import { Modal } from "react-bootstrap"
import { ReactNode, useState } from "react";
import useConfig from "../../hooks/config/useConfig"
import { NotarialForm } from "./NotarialForm";

export const NotarialModal = (
    { show, setShow} : 
    { show: boolean; setShow: (x: boolean) => void }) => {
    const handleClose = () => setShow(false);
    const { country } = useConfig();

    
    const finishedSend = () => {
        setTimeout(() => {
            setShow(false)
        }, 1000);

    }

    return (
        <Modal
            show={show}
            onHide={() => handleClose()}
            centered
            contentClassName="border-0 warranty-modal"
            size="xl"
        >
            <Modal.Header closeButton className={"px-5 bg-blue" }>
                <Modal.Title className="text-white">
                    { country === "PA" ? "Servicios Legales" : "Servicios Notariales"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="container p-4">
                <div className="row gx-5">
                { country === "PA" ? (
                    <div className="col sm:col-12 md:col-4 px-5 fw-bold">
                        <p className="mb-3" style={{ textAlign: "justify" }}>Ofrecé un servicio integral a tus clientes y gana una comisión del 15% más ITBMS de los honorarios legales.</p>
                        <p style={{ textAlign: "justify" }}>
                        Si tus clientes no cuentan con un abogado de confianza para  los trámites de compraventa, servicios migratorios, hipoteca y/o necesita asesoramiento en otros servicios legales, <b>Abogados en Panamá</b> se encarga de gestionar toda la documentacion necesaria.
                        </p>
                        <div className="modal-warranty-contact d-flex justify-content-center align-items-center flex-column mt-4 mb-5">
                            <div className="d-flex justify-content-center">
                                <p className="pb-0 mb-0 fw-bold me-3" style={{ textAlign: "center" }}>¿Te interesa recibir más información?</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <a 
                                    href={`https://api.whatsapp.com/send?phone=50761182796&text=Hola, necesito información sobre Servicios Notariales en Iris`}
                                    target="_blank">
                                        <button className="text-green border-color-green bg-white rounded p-2 fw-bold mt-2" >Contactá un asesor</button>
                                </a>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="col sm:col-12 md:col-4 px-5 fw-bold">
                        <p className="mb-3">Ofrecé un servicio integral a tus clientes y gana una comisión del 20% + IVA de los honorarios notariales.</p>
                        <p>Si tus clientes no tienen escribanos para la compra-venta, hipoteca o promesa, Estudio360 se encarga de gestionar toda la documentación necesaria.
                        </p>
                        <p>Se acredita la comisión inmobiliaria en un plazo máximo de 5 días hábiles posterior a la firma. </p>
                        <div className="modal-warranty-contact d-flex justify-content-start align-items-center mt-4 mb-5">
                            <p className="pb-0 mb-0 fw-bold me-3">¿Te interesa recibir más información?</p>
                            <a 
                                href={`https://api.whatsapp.com/send?phone=59897259343&text=Hola, necesito información sobre Servicios Notariales en Iris`}
                                target="_blank">
                                    <button className="text-green border-color-green bg-white rounded p-2 fw-bold" >Contactá un asesor</button></a>
                        </div>
                    </div>
                )}
                    <div className="col sm:col-12 md:col-4 px-5 mb-2">
                        <NotarialForm show={show} onSend={finishedSend} />
                    </div>
                { country != "PA" &&
                    <div className="col sm:col-12 md:col-4 px-5 ">
                        <h5 >Honorarios del Estudio</h5>
                        
                        <ul className="ps-3 d-grid gap-3 mt-4">
                            <li>Honorarios compra-venta: 2% + IVA del valor de la transacción (aportes notariales y gastos registrales no incluido)</li>
                            <li>Honorarios en caso de hipoteca: 1% + IVA del valor del crédito (aportes notariales y gastos registrales no incluido) </li>
                            <li>Honorarios en caso de promesas: 1.2% + IVA del valor del precio (aportes notariales y gastos registrales no incluido)</li>
                        </ul>

                    </div>
                }
                </div>
            </Modal.Body>
        </Modal>
    )
}